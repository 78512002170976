import * as React from 'react'
import DebugJsonData from '../debug-json-data'
import RichContent from '../utilities/RichContent'

const HeroWithTableOfContents = ({ data, files }) => {
  const dataMapped = {
    contentTypeAlias: data.contentTypeAlias,
    subHeading: data.subHeading,
    tableOfContents: data.tableOfContents,
    title: data.heading
  }
  return (
    <>
      <div className="component wes-toc-banner">
        <div className="wes-toc-banner__wrapper">
          <div className="wes-toc-banner__body">
            <div className="wes-toc-banner__content">
              {dataMapped.title && (
                <h1 className="wes-toc-banner__title field-heading">{dataMapped.title}</h1>
              )}
              <div className="wes-toc-banner__message">
                {dataMapped.subHeading && (
                  <RichContent content={dataMapped.subHeading} files={files} />
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="wes-toc-banner__toc-background">
          <div className="wes-toc-banner__toc-wrapper">
            <div className="wes-toc-banner_toc-body wes-list wes-list-chevron column-2">
              {dataMapped.tableOfContents && (
                <RichContent
                  className="wes-toc-banner__toc-link wes-toc-banner__toc-content"
                  content={dataMapped.tableOfContents}
                  files={files}
                />
              )}
            </div>
          </div>
        </div>
        <div className="wes-toc-banner__shapes">
          <svg
            xmlnsXlink="http://www.w3.org/1999/xlink"
            id="wes-toc-banner__shapes-desktop"
            viewBox="0 0 1366 600"
            xmlns="http://www.w3.org/2000/svg">
            <defs>
              <rect height="600" id="toc-supergraphic_xl-a" width="1366" x="0" y="0" />
              <linearGradient
                id="toc-supergraphic_xl-c"
                x1="36.892%"
                x2="63.109%"
                y1="0%"
                y2="100%">
                <stop offset="0%" stopColor="#FEBD11" />
                <stop offset="42.422%" stopColor="#FEDD36" stopOpacity=".3" />
                <stop offset="100%" stopColor="#FEDD36" stopOpacity=".1" />
              </linearGradient>
            </defs>
            <g fill="none" fillRule="evenodd">
              <mask fill="#fff" id="toc-supergraphic_xl-b">
                <use xlinkHref="#toc-supergraphic_xl-a" />
              </mask>
              <use xlinkHref="#toc-supergraphic_xl-a" fill="#FFF" />
              <g mask="url(#toc-supergraphic_xl-b)">
                <g transform="translate(-269 -92)">
                  <polygon fill="#FEBD11" points=".629 834 .629 0 957.082 0 1259.629 834" />
                  <polygon
                    fill="url(#toc-supergraphic_xl-c)"
                    points="0 0 764 0 382 1059"
                    transform="translate(378 92)"
                  />
                </g>
              </g>
            </g>
          </svg>
          <svg
            xmlnsXlink="http://www.w3.org/1999/xlink"
            id="wes-toc-banner__shapes-mobile"
            viewBox="0 0 375 392"
            xmlns="http://www.w3.org/2000/svg">
            <defs>
              <rect height="392" id="toc-supergraphic_xs-a" width="375" x="0" y="0" />
              <linearGradient
                id="toc-supergraphic_xs-c"
                x1="36.862%"
                x2="63.139%"
                y1="0%"
                y2="100%">
                <stop offset="0%" stopColor="#FEBD11" />
                <stop offset="60%" stopColor="#FEDD36" stopOpacity=".3" />
                <stop offset="100%" stopColor="#FEDD36" stopOpacity=".1" />
              </linearGradient>
            </defs>
            <g fill="none" fillRule="evenodd">
              <mask fill="#fff" id="toc-supergraphic_xs-b">
                <use xlinkHref="#toc-supergraphic_xs-a" />
              </mask>
              <use xlinkHref="#toc-supergraphic_xs-a" fill="#FEBD11" />
              <rect fill="#FFF" height="148" mask="url(#toc-supergraphic_xs-b)" width="375" />
              <rect
                fill="#FEBD11"
                height="244"
                mask="url(#toc-supergraphic_xs-b)"
                transform="matrix(1 0 0 -1 0 540)"
                width="375"
                y="148"
              />
              <g mask="url(#toc-supergraphic_xs-b)">
                <g transform="translate(-49 4)">
                  <polygon
                    fill="#FEBD11"
                    points="92.597 -92 573.403 -92 333 574"
                    transform="rotate(-90 357.5 216.5)"
                  />
                  <polygon
                    fill="url(#toc-supergraphic_xs-c)"
                    points="58.88 -58.5 365.12 -58.5 212 365.5"
                    transform="matrix(0 -1 -1 0 365.5 381.5)"
                  />
                </g>
              </g>
            </g>
          </svg>
        </div>
      </div>
      <DebugJsonData data={dataMapped} title="HeroWithTableOfContents Component data:" />
    </>
  )
}
export default HeroWithTableOfContents
