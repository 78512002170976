import * as React from 'react'
import DebugJsonData from '../debug-json-data'
import cx from 'classnames'
import RichContent from '../utilities/RichContent'

const ContentSection = ({ data, files }) => {
  const dataMapped = {
    contentTypeAlias: data.contentTypeAlias,
    sectionBackgroundColour: data.sectionBackgroundColour,
    contentSection: data.contentSection,
    showContentLeftOrCenter: data.showContentLeftOrCenter,
    extraMargin: data.extraMargin
  }

  const isGreyBg = dataMapped.sectionBackgroundColour === 'Grey'

  const isCenterAligned = dataMapped.showContentLeftOrCenter === 'Center'
  const isMargin = dataMapped.extraMargin
  return (
    <>
      {dataMapped.contentSection && (
        <div
          className={cx(
            'component wes-content-block',
            !isMargin && 'wes-content-block--collapse',
            isGreyBg && 'wes-content-block--grey',
            isCenterAligned && 'wes-content-block--right'
          )}>
          <div className="wes-content-block__wrapper">
            <div className="wes-content-block__body">
              <div className="wes-content-block__body-content">
                <div className="wes-content-block__placeholder">
                  <RichContent
                    className="component wes-rich-text-scope"
                    content={dataMapped.contentSection}
                    files={files}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      <DebugJsonData date={dataMapped} title="SectionContent Component data:" />
    </>
  )
}
export default ContentSection
