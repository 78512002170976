import React from 'react'
import PullQuote from './nested-content-elements/pull-quote'
import Cta from './nested-content-elements/cta'
import TwoColumnCTAs from './nested-content-elements/two-column-ctas'
import ThreeColumnCTAs from './nested-content-elements/three-column-ctas'
import ContentSection from './nested-content-elements/content-section'
import UsefulLinks from './nested-content-elements/useful-links'
import ContentHighlighted from './nested-content-elements/content-highlighted'
import TeamMembers from './nested-content-elements/team-members'
import YellowSection from './nested-content-elements/yellow-section'
import ContentWithImage from './nested-content-elements/content-with-image'
import DebugJsonData from './debug-json-data'
import HeroWithImage from './nested-content-elements/hero-with-image'
import HeroStandard from './nested-content-elements/hero-standard'
import HeroWithTableOfContents from './nested-content-elements/hero-with-table-of-contents'
import Sitemap from './nested-content-elements/sitemap'

const NestedContent = ({ data, files, sitemap }) => {
  return (
    <>
      {data &&
        data.map((nestedItem, index) => {
          switch (nestedItem.contentTypeAlias) {
            case 'heroStandard':
              return <HeroStandard key={'nestedItem-' + index} data={nestedItem} files={files} />

            case 'heroWithImage':
              return (
                <HeroWithImage
                  key={'nestedItem-' + index}
                  data={nestedItem}
                  images={files}
                  files={files}
                />
              )
            case 'heroWithTableOfContents':
              return (
                <HeroWithTableOfContents
                  key={'nestedItem-' + index}
                  data={nestedItem}
                  files={files}
                />
              )

            case 'pullQuote':
              return <PullQuote key={'nestedItem-' + index} data={nestedItem} />

            case 'cTA':
              return <Cta key={'nestedItem-' + index} data={nestedItem} />

            case 'twoColumnCTAs':
              return (
                <TwoColumnCTAs
                  key={'nestedItem-' + index}
                  data={nestedItem}
                  nestedItemIndex={index}
                />
              )

            case 'threeColumnCTAs':
              return <ThreeColumnCTAs key={'nestedItem-' + index} data={nestedItem} />

            case 'contentSection':
              return <ContentSection key={'nestedItem-' + index} data={nestedItem} files={files} />

            case 'usefulLinks':
              return (
                <UsefulLinks
                  key={'nestedItem-' + index}
                  data={nestedItem}
                  nestedItemIndex={index}
                  files={files}
                />
              )

            case 'contentHighlighted':
              return (
                <ContentHighlighted key={'nestedItem-' + index} data={nestedItem} files={files} />
              )

            case 'teamMember':
              return (
                <TeamMembers
                  key={'nestedItem-' + index}
                  data={nestedItem}
                  images={files}
                  files={files}
                />
              )

            case 'yellowSection':
              return <YellowSection key={'nestedItem-' + index} data={nestedItem} files={files} />

            case 'contentWithImage':
              return (
                <ContentWithImage
                  key={'nestedItem-' + index}
                  data={nestedItem}
                  images={files}
                  files={files}
                />
              )
            case 'sitemap':
              return <Sitemap key={'nestedItem-' + index} data={sitemap} />

            default:
              break
          }
        })}

      <DebugJsonData data={data} title="NestedContent data" />
    </>
  )
}

export default NestedContent
