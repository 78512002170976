import React from 'react'

const Layout = ({ children }) => {
  return (
    <>
      <main>
        <div id="content">
          <div>
            <div className="component content">
              <div className="component-content">
                <div className="component snippet">
                  <div className="component-content">
                    <div className="snippet-inner">
                      <div className="snippet-container">
                        <div className="snippet">{children}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  )
}

export default Layout
