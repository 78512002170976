import React, { useEffect, useState } from 'react'
import { useCookies } from 'react-cookie'
import { Helmet } from 'react-helmet'
import cx from 'classnames'
import { formGroup } from './alert.module.scss'
import RichContent from './RichContent'

export default function Alert({ previewData, data, files }) {
  if (!data || Object.keys(data).length === 0 || typeof data !== 'object' || !data.edges || data.edges.length === 0) {
    return <></>
  }
  const [cookies, setCookie] = useCookies(['alert'])
  const [pValue, getPadding] = useState(0)
  const [showClass, setShowClass] = useState(true)
  const [showModal, setShowModal] = useState(false)
  const [addClass, setAddClass] = useState(true)
  const [isChecked, setIsChecked] = useState(false)

  const handleOnChange = () => {
    setIsChecked(!isChecked)
  }
  const alert = previewData ? previewData : data.edges[0].node
  const hash = alert.id + alert.updateDate
  const handleCookie = () => {
    setShowClass(false)
    getPadding(0)

    if (isChecked) {
      setCookie('alert', hash, {
        path: '/',
        expires: new Date(Date.now() + 2592000), //30days
        maxAge: 2592000 //30days
      })
    }
    setTimeout(() => {
      setAddClass(false)
    }, 200)
    setTimeout(() => {
      setShowModal(false)
    }, 200)
  }
  useEffect(() => {
    setTimeout(() => {
      let scrollbarWidth =
        window.innerWidth -
        (document.body.getBoundingClientRect().left + document.body.getBoundingClientRect().right)
      getPadding(scrollbarWidth)
      if (!cookies.alert || (cookies.alert !== '' && cookies.alert !== hash)) {
        setShowModal(true)
      }
    }, 3000)
  }, [])
  return (
    <>
      {showModal && (
        <>
          <Helmet>
            <style type="text/css">{`body{padding-right:${pValue}px}`}</style>
            <body className={cx(showClass && 'modal-open')} />
          </Helmet>
          <div
            className={`modal fade ${showClass ? 'show' : ''}`}
            style={{ display: addClass ? 'block' : 'none' }}
            tabIndex="-1"
            role="dialog">
            <div className="modal-dialog modal-dialog-centered" role="document">
              <div className="modal-content">
                <div className="modal-body wes-rich-text-campaign-scope wes-text-s">
                  <h4>{alert.heading}</h4>
                  {alert.alertContent && <RichContent content={alert.alertContent} files={files} />}
                </div>
                <div className="modal-footer wes-text-s d-flex align-items-center flex-nowrap">
                  <div className={formGroup}>
                    <input
                      type="checkbox"
                      id="dontShowAgain"
                      name="dontShowAgain"
                      value="dontShowAgain"
                      checked={isChecked}
                      onChange={handleOnChange}
                    />
                    <label htmlFor="dontShowAgain">Don't show again</label>
                  </div>
                  <div>
                    <button
                      type="button"
                      className="wes-billboard__cta wes-button wes-button--on-light"
                      data-variantfieldname="BillboardCTA"
                      data-dismiss="modal"
                      onClick={handleCookie}>
                      Close
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className={`modal-backdrop fade ${showClass ? 'show' : ''}`}
            style={{ display: addClass ? 'block' : 'none' }}></div>
        </>
      )}
    </>
  )
}
