import * as React from 'react'
import DebugJsonData from '../debug-json-data'
import RichContent from '../utilities/RichContent'

const ContentHighlighted = ({ data, files }) => {
  const dataMapped = {
    contentTypeAlias: data.contentTypeAlias,
    contentHighlighted: data.contentHighlighted
  }

  return (
    <>
      {dataMapped.contentHighlighted && (
        <div className="component wes-callout wes-callout--right wes-callout--collapse">
          <div className="wes-callout__wrapper">
            <div className="wes-callout__body">
              <div className="wes-callout__body-content">
                <RichContent
                  className="wes-callout__frame"
                  content={dataMapped.contentHighlighted}
                  files={files}
                />
              </div>
            </div>
          </div>
        </div>
      )}
      <DebugJsonData data={dataMapped} title="BoxContent Component data:" />
    </>
  )
}
export default ContentHighlighted
