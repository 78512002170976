import * as React from 'react'
import DebugJsonData from '../debug-json-data'
import UsefulLink from './useful-link'

const UsefulLinks = ({ data, nestedItemIndex, files }) => {
  const dataMapped = {
    contentTypeAlias: data.contentTypeAlias,
    usefulLinksNestedContent: data.usefulLinks
  }
  return (
    <>
      <div className="component wes-content-block wes-content-block--grey">
        <div className="wes-content-block__wrapper">
          <div className="wes-content-block__body">
            <div className="wes-content-block__body-content">
              <div className="wes-content-block__placeholder">
                <div className="component wes-rich-text-scope">
                  <h2>Useful links</h2>
                  <ul className="wes-core-resource-link">
                    {dataMapped.usefulLinksNestedContent.map((nestedItem, index) => {
                      switch (nestedItem.contentTypeAlias) {
                        case 'usefulLink':
                          return (
                            <UsefulLink
                              key={'nestedItem-' + nestedItemIndex + '-' + index}
                              data={nestedItem}
                              files={files}
                            />
                          )

                        default:
                          break
                      }
                    })}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <DebugJsonData data={dataMapped} title="UsefulLinks Component data:" />
    </>
  )
}
export default UsefulLinks
