import * as React from 'react'
import DebugJsonData from '../debug-json-data'
import Cta from './cta'

const TwoColumnCTAs = ({ data, nestedItemIndex }) => {
  const dataMapped = {
    contentTypeAlias: data.contentTypeAlias,
    heading: data.heading,
    twoColumnCTAs: data.twoColumnCTAs
  }

  return (
    <>
      <div className="component wes-card-deck">
        <div className="wes-card-deck__wrapper">
          {dataMapped.heading && (
            <div className="wes-card-deck__header">
              <div className="wes-card-deck__header-content">
                <h2 className="wes-card-deck__title field-carddecktitle">{dataMapped.heading}</h2>
              </div>
            </div>
          )}
          <div className="wes-card-deck__deck">
            {dataMapped.twoColumnCTAs.map((nestedItem, index) => {
              switch (nestedItem.contentTypeAlias) {
                case 'cta':
                  return (
                    <div
                      className="col-lg-6 mb-3"
                      key={'nestedItem-' + nestedItemIndex + '-' + index}>
                      <Cta data={nestedItem} />
                    </div>
                  )
                default:
                  break
              }
            })}
          </div>
        </div>
      </div>

      <DebugJsonData data={dataMapped} title="TwoColumnCTAs data" />
    </>
  )
}
export default TwoColumnCTAs
