import React from 'react'
import DebugJsonData from '../debug-json-data'

const Cta = ({ data }) => {
  const dataMapped = {
    contentTypeAlias: data.contentTypeAlias,
    boxContent: data.boxContent,
    heading: data.heading,
    link: data.link
  }
  return (
    <>
      <article className="wes-card-deck__card h-100">
        <div className="wes-card-deck__card-bg"></div>
        <div className="wes-card-deck__card-icon"></div>
        <div className="wes-card-deck__card-body h-100">
          <div className="wes-card-deck__card-header">
            <h2 className="wes-card-deck__card-title field-cardtitle">{data.heading}</h2>
          </div>
          <div className="wes-card-deck__card-message">
            {dataMapped.boxContent && <p>{dataMapped.boxContent}</p>}
          </div>
          <div className="wes-card-deck__card-footer">
            {dataMapped.link && (
              <a href={dataMapped.link.url} className="wes-card-deck__card-cta icon-right-arrow">
                {dataMapped.link.name}
              </a>
            )}
          </div>
        </div>
      </article>

      <DebugJsonData date={dataMapped} title="CTA Component data:" />
    </>
  )
}
export default Cta
