import React, { useState } from 'react'
import cx from 'classnames'
export default function FooterNavItem({ data, id }) {
  const [footerNav, setFooterNav] = useState(false)
  const footerNavHandler = (e) => {
    e.preventDefault()
    setFooterNav(!footerNav)
  }
  return (
    <div className="footer-data-item col-12 col-lg-3">
      <div aria-level="2" role="heading">
        <button
          aria-expanded={footerNav}
          className={`d-lg-none button-style`}
          data-target={`#collapseFooter-${id}`}
          data-toggle="collapse"
          onClick={footerNavHandler}>
          {data.heading}
        </button>
        <div className="d-none d-lg-block">{data.heading}</div>
      </div>
      <ul className={cx('collapse', footerNav && 'show')} id={`collapseFooter-${id}`}>
        {data.navigationItems.map((navItem, idx) => (
          <li className="" key={`footerNavChild${navItem.udi}`}>
            <a href={navItem.url} target={`${navItem.target ? navItem.target : ''}`}>
              {navItem.name}
            </a>
          </li>
        ))}
      </ul>
    </div>
  )
}
