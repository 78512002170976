import React from 'react'
import FooterNavItem from './utilities/footerNavItem'
import ScrollToTop from './utilities/scrollToTop'
import RichContent from './utilities/RichContent'

export default function Footer({ previewData, data, files }) {
  data = previewData ? previewData : data

  if (!data || Object.keys(data).length === 0) {
    return <></>
  }
  const copyright = data.copyright
  const footerNavigation = data.footerNavigation
  const footerSubNavigation = data.footerSubNavigation
  return (
    <>
      <footer className="c-footer mt-5 wes-footer">
        <div className="container link-top">
          <div className="row">
            <div className="col pt-3 pb-3">
              <ScrollToTop />
            </div>
          </div>
        </div>
        {footerNavigation && footerNavigation.length > 0 && (
          <div className="container footer-top-border pt-3">
            <div className="row">
              {footerNavigation.map((item, idx) => (
                <FooterNavItem key={`footerNavItem${idx}`} data={item} id={idx} />
              ))}
            </div>
          </div>
        )}
        {footerSubNavigation && footerSubNavigation.length > 0 && (
          <div className="container footer-top-border pt-3">
            <div className="footer-data-item">
              <ul className="row bottom-nav">
                {footerSubNavigation.map((subNavItem, idx) => (
                  <li key={idx} className="col-6 col-md-4 col-lg-auto">
                    <a href={subNavItem.url} target="_blank" rel="noreferrer">
                      {subNavItem.name}
                    </a>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        )}
        {copyright && (
          <div className="wes-bg-solid-grey-50 pt-3 pb-3">
            <div className="container">
              <div className="row">
                <div className="col-12">
                  <RichContent className="footer-text-sm" content={copyright} files={files} />
                </div>
              </div>
            </div>
          </div>
        )}
      </footer>
    </>
  )
}
