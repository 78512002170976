import * as React from 'react'
import DebugJsonData from '../debug-json-data'
import RichContent from '../utilities/RichContent'

const HeroStandard = ({ data, files }) => {
  const dataMapped = {
    contentTypeAlias: data.contentTypeAlias,
    subHeading: data.subHeading,
    cTAButton: data.cTAButton,
    footNote: data.footNote,
    heading: data.heading,
    heroColour: data.heroColour
  }
  const isGrey = dataMapped.heroColour === 'Grey'
  return (
    <>
      <div className={`component wes-header ${isGrey ? 'grey-header' : 'white-header'}`}>
        <div className="component container">
          <h1 className="wes-text-mega field-heading">{dataMapped.heading}</h1>
          {dataMapped.subHeading && (
            <RichContent className="sub-heading" content={dataMapped.subHeading} files={files} />
          )}
          {dataMapped.cTAButton && dataMapped.cTAButton.url && (
            <div class="wes-hero-banner-2__actions">
              <a
                class="wes-billboard__cta wes-button wes-button--on-light"
                href={dataMapped.cTAButton.url}
                target={dataMapped.cTAButton.target}>
                {dataMapped.cTAButton.name}
              </a>
            </div>
          )}
          {dataMapped.footNote && (
            <RichContent
              className="wes-rich-text-scope wes-hero-banner-2__footnote"
              content={dataMapped.footNote}
              files={files}
            />
          )}
        </div>
      </div>

      <DebugJsonData data={data} title="HeroStandard Component data:" />
    </>
  )
}

export default HeroStandard
