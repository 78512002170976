import * as React from 'react'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import DebugJsonData from '../debug-json-data'
import placeholder from '../../images/placeholder.jpg'
import RichContent from '../utilities/RichContent'

const HeroWithImage = ({ data, images, files }) => {
  const dataMapped = {
    contentTypeAlias: data.contentTypeAlias,
    subHeading: data.subHeading,
    cTAButton: data.cTAButton,
    footNote: data.footNote,
    image: data.image,
    heading: data.heading
  }
  let imageUrl = placeholder
  if (dataMapped.image && dataMapped.image.url.length > 0) {
    imageUrl = dataMapped.image.url
  }
  let isImgSharp = false
  if (images && images.length > 0) {
    const imgSharp = images.find((node) => node.url === data.image.url)
    isImgSharp = getImage(imgSharp.img800) !== undefined
    if (isImgSharp) {
      imageUrl = getImage(imgSharp.img800)
    }
  }
  const showImgSharp = images && isImgSharp
  return (
    <>
      <div role="banner">
        <div className="wes-hero-banner-2">
          <div className="wes-hero-banner-2__wrapper">
            <div className="wes-hero-banner-2__body">
              <div className="wes-hero-banner-2__content">
                <h1 className="wes-hero-banner-2__title field-hero-title">{dataMapped.heading}</h1>
                {dataMapped.subHeading && (
                  <div className="wes-hero-banner-2__message">
                    <RichContent
                      role="heading"
                      ariaLevel="2"
                      className="field-hero-text"
                      content={dataMapped.subHeading}
                      files={files}
                    />
                  </div>
                )}
                {dataMapped.cTAButton && dataMapped.cTAButton.url && (
                  <div class="wes-hero-banner-2__actions">
                    <a
                      class="wes-hero-banner-2__cta"
                      href={dataMapped.cTAButton.url}
                      target={dataMapped.cTAButton.target}>
                      {dataMapped.cTAButton.name}
                    </a>
                  </div>
                )}
                {dataMapped.footNote && (
                  <RichContent
                    className="wes-hero-banner-2__footnote"
                    content={dataMapped.footNote}
                    files={files}
                  />
                )}
              </div>
            </div>
          </div>
          {!showImgSharp && (
            <div
              className="wes-hero-banner-2__image"
              style={{ backgroundImage: `url(${imageUrl})` }}
            />
          )}
          {showImgSharp && (
            <div className="wes-hero-banner-2__image">
              <GatsbyImage
                image={imageUrl}
                alt={dataMapped.image.name}
                style={{ height: '100%', position: 'static' }}
              />
            </div>
          )}
          <div className="wes-hero-banner-2__shapes" />
        </div>
      </div>

      <DebugJsonData data={data} title="HeroWithImage Component data:" />
    </>
  )
}

export default HeroWithImage
