import { graphql, useStaticQuery } from 'gatsby'
import React from 'react'
export default function RichContent({ content, className, files, role, ariaLevel }) {
  const imgRegexp = /("|')(https:\/\/media\.umbraco\.io\/.*?)("|')/g
  let editorContent = content
  const filesQuery = files
  if (filesQuery && filesQuery.length > 0) {
    let filteredContentSRC = []

    let imgsMatch = imgRegexp.exec(editorContent)
    while (imgsMatch != null) {
      filteredContentSRC.push(imgsMatch[2])
      imgsMatch = imgRegexp.exec(editorContent)
    }
    filteredContentSRC = [...new Set(filteredContentSRC)]
    let imgNameCollector = filteredContentSRC.map((src) => {
      if (src.indexOf('?') >= 0) {
        src = src.slice(0, src.indexOf('?'))
        if (src.slice(-1) === '/') {
          src = src.slice(0, -1).split('/').pop()
        } else {
          src = src.split('/').pop()
        }
      } else {
        src = src.split('/').pop()
      }
      return src
    })
    for (var i = 0; i < filteredContentSRC.length; i++) {
      let test = null
      test = filesQuery.find(({ base }) => imgNameCollector[i] === base)
      if (test && Object.keys(test).length !== 0) {
        let oldSrc = filteredContentSRC[imgNameCollector.indexOf(test.base)]
        let newSrc = test.childImageSharp !== null ? test.childImageSharp.fluid.src : test.publicURL
        editorContent = editorContent.replace(oldSrc, newSrc)
      }
    }
  }
  return (
    <>
      <div
        className={className ? className : ''}
        role={role ? role : ''}
        aria-level={ariaLevel ? ariaLevel : ''}
        dangerouslySetInnerHTML={{ __html: editorContent }}></div>
    </>
  )
}
