import * as React from 'react'
import DebugJsonData from '../debug-json-data'
import RichContent from '../utilities/RichContent'

const YellowSection = ({ data, files }) => {
  const dataMapped = {
    contentTypeAlias: data.contentTypeAlias,
    heading: data.heading,
    link: data.link,
    yellowContentSection: data.yellowContentSection
  }

  const buttonText = dataMapped.link.name
  const buttonUrl = dataMapped.link.url

  return (
    <>
      <div className="component wes-promo wes-promo--shout wes-promo--grey-bottom" role="banner">
        <div className="wes-promo__wrapper">
          <div className="wes-promo__body">
            <div className="wes-promo__trapezoid" />
            <div className="wes-promo__content">
              <h2 className="wes-promo__title field-promo-title">{dataMapped.heading}</h2>
              {dataMapped.yellowContentSection && (
                <RichContent
                  className="wes-promo__message"
                  content={dataMapped.yellowContentSection}
                  files={files}
                />
              )}
              <div className="wes-promo__actions">
                <a
                  href={buttonUrl}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="wes-button wes-button--on-gold button-icon-arrow-right">
                  {buttonText}
                </a>
              </div>
              <div className="wes-promo__footnote">
                <p className="field-promo-footnote" />
              </div>
            </div>
          </div>
        </div>
      </div>

      <DebugJsonData data={dataMapped} title="YellowSection Component data:" />
    </>
  )
}
export default YellowSection
