import * as React from 'react'

const DebugJsonData = ({ data, title }) => {
  // console.log(title, data)

  if (process.env.SHOW_JSON_DEBUG_DATA === 'true') {
    return (
      <>
        {title && title.length > 0 && <p>{title}</p>}
        <pre>{JSON.stringify(data, null, 2)}</pre>
      </>
    )
  } else {
    return <></>
  }
}

export default DebugJsonData
