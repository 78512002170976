import * as React from 'react'
import DebugJsonData from '../debug-json-data'
import cx from 'classnames'
import placeholder from '../../images/placeholder-team-member.jpg'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import RichContent from '../utilities/RichContent'

const TeamMembers = ({ data, images, files }) => {
  const dataMapped = {
    contentTypeAlias: data.contentTypeAlias,
    alignImage: data.alignImage,
    company: data.company,
    description: data.description,
    fullName: data.fullName,
    image: data.image,
    jobTitle: data.jobTitle
  }

  var jobTitleAndCompany = dataMapped.jobTitle + ', ' + dataMapped.company
  if (!dataMapped.jobTitle) {
    jobTitleAndCompany = dataMapped.company
  }
  let imageUrl = placeholder
  if (dataMapped.image && dataMapped.image.url.length > 0) {
    imageUrl = dataMapped.image.url
  }
  let isImgSharp = false
  if (images && images.length > 0) {
    const imgSharp = images.find((node) => node.url === data.image.url)
    isImgSharp = getImage(imgSharp.img800) !== undefined
    if (isImgSharp) {
      imageUrl = getImage(imgSharp.img800)
    }
  }
  const showImgSharp = images && isImgSharp

  const isLeftAligned = dataMapped.alignImage === 'Left Align Image'

  return (
    <>
      <div
        className={cx(
          'component wes-billboard wes-billboard--collapse',
          isLeftAligned && 'wes-billboard--reverse'
        )}>
        <div className="wes-billboard__wrapper">
          <div className="wes-billboard__body">
            <div className="wes-billboard__body-content">
              <div className="wes-billboard__content">
                <h2 className="wes-billboard__title field-billboardtitle">{dataMapped.fullName}</h2>
                <div className="wes-billboard__message">
                  {jobTitleAndCompany && (
                    <p>
                      <strong>{jobTitleAndCompany}</strong>
                    </p>
                  )}
                  {dataMapped.description && (
                    <RichContent content={dataMapped.description} files={files} />
                  )}
                </div>
                <div className="wes-billboard__actions" />
              </div>
            </div>
            <div className="wes-billboard__bg">
              {!showImgSharp && (
                <div
                  className="wes-billboard__bg-image"
                  style={{ backgroundImage: `url(${imageUrl})` }}
                />
              )}
              {showImgSharp && (
                <div className="wes-billboard__bg-image">
                  <GatsbyImage
                    image={imageUrl}
                    alt={dataMapped.image.name}
                    style={{ height: '100%' }}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      <DebugJsonData date={dataMapped} title="TeamMembers Component data:" />
    </>
  )
}
export default TeamMembers
