import React from 'react'
import DebugJsonData from '../debug-json-data'

const UsefulLink = ({ data, files }) => {
  const dataMapped = {
    contentTypeAlias: data.contentTypeAlias,
    usefulLink: data.usefulLink
  }

  const isMediaUrl = dataMapped.usefulLink.type === 'MEDIA'
  const isExternalUrl = dataMapped.usefulLink.type === 'EXTERNAL'
  var fileSizeSuffix = ''
  var liClassName = 'wes-font-icon-web-link'
  let linkURL = dataMapped.usefulLink.url

  if (isMediaUrl) {
    let fileObj = ''
    if (files && files.length > 0) {
      fileObj = files.find((file) => file.url && file.url === data.usefulLink.url)
    }
    liClassName = 'wes-font-icon-sheet'
    if (fileObj) {
      fileSizeSuffix = ` ${
        fileObj.size > 1024 * 1024
          ? fileObj.extension.toUpperCase() +
            ' (' +
            (fileObj.size / (1024 * 1024)).toFixed(2) +
            'MB)'
          : fileObj.extension.toUpperCase() + ' (' + (fileObj.size / 1024).toFixed(2) + 'KB)'
      }`
    }
    linkURL = fileObj.publicURL
  }

  return (
    <>
      <li className={`${isMediaUrl ? 'wes-font-icon-sheet' : 'wes-font-icon-web-link'}`}>
        <a href={linkURL}>{dataMapped.usefulLink.name}</a>
        {`${isMediaUrl ? fileSizeSuffix : ''}`}
      </li>

      <DebugJsonData date={dataMapped} title="UsefulLink Component data:" />
    </>
  )
}
export default UsefulLink
