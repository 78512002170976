import * as React from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'

function Seo({ description, lang, meta, title, name, data }) {
  data =
    data && data.siteMetadata && data.siteMetadata.description
      ? data
      : {
          siteMetadata: {
            title: `Wesleyan Bank`,
            description: `Wesleyan Bank helps both individuals and businesses do more with their money - from personal savings to commercial finance.`,
            author: `@wesleyan`,
          }
        }
  const metaDescription = description || data.siteMetadata.description
  const pageTitle = title ? title : (name ? name : data.siteMetadata?.title)
  const siteName = data.siteMetadata?.title
  const combinedTitle = pageTitle !== siteName ? pageTitle + " | " + siteName : siteName

  return (
    <Helmet
      htmlAttributes={{
        lang
      }}
      title={`${combinedTitle}`}
      meta={[
        {
          name: `description`,
          content: metaDescription
        },
        {
          property: `og:title`,
          content: pageTitle
        },
        {
          property: `og:description`,
          content: metaDescription
        },
        {
          property: `og:type`,
          content: `website`
        },
        {
          name: `twitter:card`,
          content: `summary`
        },
        {
          name: `twitter:creator`,
          content: data.siteMetadata?.author || ``
        },
        {
          name: `twitter:title`,
          content: pageTitle
        },
        {
          name: `twitter:description`,
          content: metaDescription
        }
      ].concat(meta)}
    />
  )
}

Seo.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``
}

Seo.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired
}

export default Seo
