import * as React from 'react'
import DebugJsonData from '../debug-json-data'
import Cta from './cta'

const ThreeColumnCTAs = ({ data, nestedItemIndex }) => {
  const dataMapped = {
    contentTypeAlias: data.contentTypeAlias,
    heading: data.heading,
    threeColumnCta: data.threeColumnCTAs
  }

  return (
    <>
      <div className="component wes-card-deck">
        <div className="wes-card-deck__wrapper">
          <div className="wes-card-deck__header">
            <div className="wes-card-deck__header-content">
              <h2 className="wes-card-deck__title field-carddecktitle">{dataMapped.heading}</h2>
            </div>
          </div>
          <div className="wes-card-deck__deck">
            {dataMapped.threeColumnCta.map((nestedItem, index) => {
              switch (nestedItem.contentTypeAlias) {
                case 'cta':
                  return (
                    <div
                      className="col-lg-4 mb-3"
                      key={'nestedItem-' + nestedItemIndex + '-' + index}>
                      <Cta data={nestedItem} />
                    </div>
                  )
                default:
                  break
              }
            })}
          </div>
        </div>
      </div>

      <DebugJsonData data={dataMapped} title="ThreeColumnCTAs Component data:" />
    </>
  )
}

export default ThreeColumnCTAs
