import React from 'react'

const Sitemap = ({ data }) => {
  const isData = data && data.edges.length > 0
  if (!isData) {
    return <></>
  }
  const filter = data.edges.filter(({ node }) => {
    if (node.children.length > 0) {
      return node
    }
  })
  let listOfChildren = []
    .concat(filter.map(({ node }) => node.children.map(({ slug }) => slug)))
    .flat(2)

  const items = data.edges.filter(({ node }) => {
    if (!listOfChildren.includes(node.slug)) {
      return node
    }
  })
  return (
    <>
      <div class="component navigation navigation-title sitemap-navigation wes-sitemap-navigation container initialized">
        <div class="component-content">
          <nav>
            <ul class="clearfix">
              {items.map(({ node }) => (
                <li class=" level1 submenu item0 odd first rel-level1">
                  <div class="navigation-title">
                    <a title="campaigns" href={node.slug}>
                      {node.name}
                    </a>
                  </div>
                  {node.children.length > 0 && (
                    <ul class="clearfix">
                      {node.children.map((subItem) => (
                        <li class=" level2 item0 odd first rel-level2">
                          <div class="navigation-title">
                            <a title="buying-a-pharmacy" href={subItem.slug}>
                              {subItem.name}
                            </a>
                          </div>
                        </li>
                      ))}
                    </ul>
                  )}
                </li>
              ))}
            </ul>
          </nav>
        </div>
      </div>
    </>
  )
}
export default Sitemap
