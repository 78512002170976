import * as React from 'react'
import { Helmet } from 'react-helmet'
import cx from 'classnames'

const Header = ({ previewData, data }) => {
  data = previewData ? previewData : data
  const [menuIsOpen, setMenuOpen] = React.useState(false)
  const [pValue, getPadding] = React.useState(0)

  const menuToggle = (e) => {
    e.preventDefault()
    setMenuOpen(!menuIsOpen)
    const paddingWidth = () => {
      const scrollbarWidth =
        window.innerWidth -
        (document.body.getBoundingClientRect().left + document.body.getBoundingClientRect().right)
      return scrollbarWidth
    }
    getPadding(paddingWidth())
  }
  return (
    <>
      <Helmet>
        <style type="text/css">{`body{padding-right:${pValue}px}`}</style>
        <body className={cx(menuIsOpen && 'menu-open')} />
      </Helmet>
      <header id="header">
        <div className="component content col-xs-12">
          <div className="component-content"></div>
        </div>
        <nav className="wes-header-navigation">
          {data && data.loginUrl && (
            <div className="wes-bg-solid-grey-900 topnavbar">
              <div className="container">
                <div className="d-flex flex-row">
                  <div className="d-flex"></div>

                  <div className="mr-auto"></div>
                  <div>
                    <a
                      data-text="Log in"
                      className="login d-none d-xl-inline-flex"
                      href={data.loginUrl.url}
                      target={data.loginUrl.target}>
                      {data.loginUrl.name}
                    </a>
                  </div>
                </div>
              </div>
            </div>
          )}
          <div className="wes-bg-solid-white masthead">
            <div className="container">
              <div className="d-flex flex-row align-items-center">
                <div className="d-flex">
                  <a aria-label="wesleyan logo" className="wes-logo bank-logo" href="/">
                    <span className="sr-only">Home</span>
                  </a>
                </div>
                <div className="mr-auto"></div>
                <div className="d-flex d-xl-none flex-wrap">
                  {data && data.loginUrl && (
                    <span className="menu-item">
                      <a
                        className="d-flex flex-column align-items-center wes-font-icon-user"
                        href={data.loginUrl.url}
                        target={data.loginUrl.target}>
                        {data.loginUrl.name}
                      </a>
                    </span>
                  )}
                  {data && data.mainNavigation && data.mainNavigation.length > 0 && (
                    <span className="menu-item">
                      <a
                        aria-controls="primaryNav"
                        aria-expanded={menuIsOpen}
                        className="d-flex flex-column align-items-center wes-font-icon-menu collapsed"
                        data-toggle="collapse"
                        href="#primaryNav"
                        role="button"
                        target=""
                        onClick={menuToggle}>
                        <span className="closed">Menu</span>
                        <span className="opened">Close</span>
                      </a>
                    </span>
                  )}
                </div>
                {data && data.featuredNavigation && data.featuredNavigation.length > 0 && (
                  <div className="d-none d-xl-flex flex-wrap">
                    {data.featuredNavigation.map((item, idx) => (
                      <span key={idx} className="menu-item">
                        <a
                          className="d-flex flex-row align-items-center"
                          href={item.url}
                          target={item.target}>
                          {item.name}
                        </a>
                      </span>
                    ))}
                  </div>
                )}
              </div>
            </div>
          </div>
          {data && data.mainNavigation && data.mainNavigation.length > 0 && (
            <>
              <div
                className={`collapse primary-nav position-relative ${menuIsOpen ? 'show' : ''}`}
                id="primaryNav">
                <div className="position-absolute w-100 wes-bg-solid-white mega-menu-shadow">
                  <div className="container-xl primary-nav-container">
                    <ul className="d-flex flex-column flex-xl-row align-items-stretch align-items-xl-center mega-menu">
                      {data.mainNavigation.map((item, idx) => (
                        <li key={idx} className="d-flex flex-column">
                          <span className="mega-menu-item">
                            <a className="container" href={item.url} target={item.target}>
                              {item.name}
                            </a>
                            <span className="overlay"></span>
                          </span>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
              <div className="underlay position-absolute d-xl-none" onClick={menuToggle}></div>
            </>
          )}
        </nav>
      </header>
    </>
  )
}

export default Header
