import * as React from 'react'
import DebugJsonData from '../debug-json-data'

const PullQuote = ({ data }) => {
  const dataMapped = {
    contentTypeAlias: data.contentTypeAlias,
    pullQuote: data.pullQuote
  }

  return (
    <>
      <div className="component wes-emphasis">
        <div className="wes-emphasis__wrapper">
          <div className="wes-emphasis__body">
            <div className="wes-emphasis__body-content">
              <div className="wes-emphasis__frame">
                <div className="wes-emphasis__message">
                  <p>{dataMapped.pullQuote}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <DebugJsonData date={dataMapped} title="Company Statement Component data:" />
    </>
  )
}
export default PullQuote
