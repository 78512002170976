import React from 'react'

export default function ScrollToTop() {
  const scrollToTop = (e) => {
    e.preventDefault()
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    })
  }
  return (
    <a className="wes-link icon-link" href="/" onClick={scrollToTop}>
      Back to top
      <span aria-hidden="true" className="wes-font-icon-arrow-right"></span>
    </a>
  )
}
