import * as React from 'react'
import DebugJsonData from '../debug-json-data'
import placeholder from '../../images/placeholder.jpg'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import RichContent from '../utilities/RichContent'

const ContentWithImage = ({ data, images, files }) => {
  const dataMapped = {
    contentTypeAlias: data.contentTypeAlias,
    boxContent: data.boxContent,
    heading: data.heading,
    image: data.image,
    link: data.link
  }
  let imageUrl = placeholder
  if (dataMapped.image && dataMapped.image.url.length > 0) {
    imageUrl = dataMapped.image.url
  }
  let isImgSharp = false

  if (images && images.length > 0) {
    const imgSharp = images.find((node) => node.url === data.image.url)
    isImgSharp = getImage(imgSharp.img1280) !== undefined
    if (isImgSharp) {
      imageUrl = getImage(imgSharp.img1280)
    }
  }
  const showImgSharp = images && isImgSharp

  const buttonUrl = dataMapped.link.url
  const buttonText = dataMapped.link.name
  return (
    <>
      <div className="component wes-billboard wes-billboard--grey">
        <div className="wes-billboard__wrapper">
          <div className="wes-billboard__body">
            <div className="wes-billboard__body-content">
              <div className="wes-billboard__content">
                <h2 className="wes-billboard__title field-billboardtitle">{dataMapped.heading}</h2>
                {dataMapped.boxContent && (
                  <RichContent
                    className="wes-billboard__message"
                    content={dataMapped.boxContent}
                    files={files}
                  />
                )}
                <div className="wes-billboard__actions">
                  <a
                    className="wes-billboard__cta wes-button wes-button--on-light"
                    href={buttonUrl}
                    data-variantfieldname="BillboardCTA">
                    {buttonText}
                  </a>
                </div>
              </div>
            </div>
            {!showImgSharp && (
              <div className="wes-billboard__bg">
                <div
                  class="wes-billboard__bg-image"
                  style={{ backgroundImage: `url(${imageUrl})` }}></div>
              </div>
            )}
            {showImgSharp && (
              <div className="wes-billboard__bg">
                <GatsbyImage
                  image={imageUrl}
                  alt={dataMapped.image.name}
                  style={{ height: '100%' }}
                />
              </div>
            )}
          </div>
        </div>
      </div>

      <DebugJsonData data={dataMapped} title="ImageContentSection Component data:" />
    </>
  )
}

export default ContentWithImage
